import CreatePostBox from 'components/CreatePostBox';
import { useMediaQuery } from 'common/utils';
import TrendingPosts from 'components/TrendingPosts';
import useStore from 'store/timeline';
import ImportantMessagesSection from 'components/common/ImportantMessagesSection';
import styles from './TimelinePage.module.scss';
import dynamic from 'next/dynamic';
const SubscribingSection = dynamic(
  () => import('components/SubscribingSection'),
  {
    ssr: false,
  }
);
const UserSpotlight = dynamic(() => import('components/UserSpotlight'), {
  ssr: false,
});

const SymbolDataView = dynamic(
  () => import('components/ChartSection/SymbolDataView'),
  {
    ssr: false,
  }
);

import { useState } from 'react';
import { ScreenName } from 'common/utils/pp_tracking';
import { SymbolTabsName } from 'common/utils/chart';
import RandomBanner from 'components/molecules/RandomBanner';
import { BannerCode } from 'common/utils/banner';
import { MissionCode } from 'service/user';
import AnimeAlarmBanner from 'components/common/AnimeAlarmBanner';
import { getCoinBackMembershipEvent } from 'common/utils/user';
import { isMembershipAccount } from 'common/utils/membership';
import CoinBack202407Banner from 'components/molecules/CoinBack202407Banner';

export default function TimelinePage(): JSX.Element {
  const isMobile = useMediaQuery('(max-width: 767px)');
  const isSmallScreen = useMediaQuery('(max-width: 1200px)');
  const currentUser = useStore((state) => state.currentUser);
  const [voteTabId, setVoteTabId] = useState(SymbolTabsName.VoteResult);
  const [symbolId, setSymbolId] = useState<number>(null);
  const coinBackEvent = getCoinBackMembershipEvent(currentUser);
  return (
    <div className={styles.timelineSection}>
      <ImportantMessagesSection />
      {currentUser &&
        (coinBackEvent && !isMembershipAccount(currentUser) ? (
          <CoinBack202407Banner className={styles.banner} />
        ) : (
          <RandomBanner
            className={styles.banner}
            codes={[
              BannerCode.Izanavi,
              MissionCode.IGCreateAccount,
              MissionCode.InvastCreateAccount,
              MissionCode.SBINeoCreateAccount,
            ]}
            isSupportRandom
            fallbackCodes={[BannerCode.Anime, BannerCode.CoinInvite]}
            fallbackElement={
              <div className={styles.banner}>
                <AnimeAlarmBanner />
              </div>
            }
          />
        ))}
      {!isMobile && (
        <div className={styles.createPost}>
          <CreatePostBox />
        </div>
      )}
      <div className={styles.timeline}>
        <div className={styles.sectionWrapper}>
          {currentUser && symbolId && (
            <SymbolDataView
              screenName={ScreenName.HomeTimeline}
              symbolId={symbolId}
              parentTabId={voteTabId}
              setParentTabId={setVoteTabId}
            />
          )}
          <TrendingPosts setSymbolId={setSymbolId} />
        </div>
        {isSmallScreen && (
          <div className={styles.sectionWrapper}>
            <UserSpotlight />
          </div>
        )}
        <SubscribingSection />
      </div>
      {/* {currentUser && !currentUser?.anime_character_onboarding && (
        <SurveyBanner />
      )} */}
    </div>
  );
}
