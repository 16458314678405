import Head from 'next/head';
import TimelinePage from 'components/TimelinePage';
import { SITE_BASE_URL } from 'config/env';
import { NextPageContext } from 'next';
import { parse } from 'cookie';
import { generateCookieList } from 'common/utils/trackingTag';
import { isProduction } from 'common/utils';

const title =
  'PostPrime | 高橋ダン企画-資産形成が学べる投資・株式予想・情報サイト';
const description =
  'PostPrimeは、高橋ダンがウォール街の経験をもとに企画した投資を実践するための学習サイトです。独自のチャート機能を使用し、資産をつくる手助けをします。';
export default function HomeTimeline(): JSX.Element {
  return (
    <>
      <Head>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta property="twitter:url" content={SITE_BASE_URL} />
        <link rel="canonical" href={SITE_BASE_URL} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta property="url" content="postprime.com" />
        <meta property="og:type" content="website" key="ogtype" />
        {isProduction() && (
          <meta
            name="facebook-domain-verification"
            content="bnygafzopgiu9k82kticxuoat5t6lz"
          />
        )}
      </Head>
      <TimelinePage />
    </>
  );
}

export const getServerSideProps = (context: NextPageContext) => {
  const cookiesList = generateCookieList(
    context.query,
    parse(context.req?.headers?.cookie || '')
  );
  if (cookiesList.length > 0) {
    context.res.setHeader('Set-Cookie', cookiesList);
  }
  return {
    props: {},
  };
};
