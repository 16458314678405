import axios from 'axios';
import classNames from 'classnames';
import { Banner, GetBannersResult } from 'common/interfaces/api';
import Icon from 'components/common/Icon';
import { useQuery, useMutation } from 'react-query';
import useGlobalStore from 'store/global';
import styles from './ImportantMessagesSection.module.scss';

const ImportantMessagesSection = (): JSX.Element => {
  const isAuthInit = useGlobalStore((state) => state.isAuthInit);
  const currentAuth = useGlobalStore((state) => state.currentAuth);
  const { data, refetch } = useQuery(
    'getBanners',
    async () =>
      (await axios.get('/profiles/system/banners')).data as GetBannersResult,
    {
      refetchOnWindowFocus: false,
      enabled: isAuthInit && !!currentAuth,
    }
  );
  const { mutate: confirmBanner } = useMutation(
    async ({
      banner,
      accept = false,
    }: {
      banner: Banner;
      accept?: boolean;
    }) => {
      const acceptParam = accept ? `?accept=true` : '';
      return axios.post(
        `/profiles/system/banners/${banner.id}/confirms?${acceptParam}`,
        {}
      );
    },
    {
      onSuccess: () => {
        void refetch();
      },
    }
  );

  const banners = (data?.data || []).filter(
    (banner) =>
      !['ai_fixed_banner', 'fixed_notification', 'fixed_url_banner'].includes(
        banner.type
      )
  );

  if (!currentAuth || banners.length === 0) return <></>;
  return (
    <div className={styles.messages}>
      {banners &&
        banners.map((banner) => (
          <div
            className={classNames(styles.message, {
              [styles[banner.severity]]: !!banner.severity,
            })}
            key={`banner-${banner.id}`}
          >
            <div
              className={styles.close}
              onClick={() => confirmBanner({ banner })}
            >
              <Icon name="close-red" width={16} height={16} />
            </div>
            <div
              className={styles.content}
              dangerouslySetInnerHTML={{ __html: bannerContent(banner) }}
            ></div>
          </div>
        ))}
    </div>
  );
};

const bannerContent = (banner: Banner): string => {
  const textContent = banner.text;
  if (!banner.url) return textContent;
  const start = textContent.indexOf(`[`);
  const end = textContent.indexOf(`]`);
  const linkText = textContent.substring(start + 1, end);
  return textContent.replace(
    `[${linkText}]`,
    `<a target="_blank" href="${banner.url}">${linkText}</a>`
  );
};

export default ImportantMessagesSection;
