import Image from 'next/image';

import styles from './Section.module.scss';

export type Props = {
  text: string;
  iconSrc?: string;
  backgroundColor?: string;
  children?: React.ReactNode;
};

export default function Section({
  iconSrc,
  text,
  backgroundColor,
  children,
}: Props): JSX.Element {
  return (
    <div className={styles.sectionWrapper}>
      <div
        className={
          backgroundColor
            ? styles.sectionHeaderColorWrapper
            : styles.sectionHeaderWrapper
        }
      >
        <div
          className={styles.sectionHeader}
          style={backgroundColor ? { background: backgroundColor } : {}}
        >
          {iconSrc && (
            <div className={styles.imageWrapper}>
              <Image src={iconSrc} width={24} height={24} />
            </div>
          )}
          <div className={styles.headerText}>{text}</div>
        </div>
      </div>
      {children}
    </div>
  );
}
